var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-static-top white-bg",
      staticStyle: { "margin-bottom": "0" },
      attrs: { id: "nav", role: "navigation" },
    },
    [
      _c("div", { staticClass: "navbar-header" }, [
        _c(
          "a",
          {
            staticClass: "navbar-minimalize minimalize-styl-2 btn btn-primary",
            attrs: { href: "#" },
            on: { click: _vm.smoothlyMenu },
          },
          [
            _vm.isHide
              ? _c("span", { staticClass: "showIcon" }, [
                  _c("i", { staticClass: "fa fa-bars shou" }),
                ])
              : _c(
                  "svg",
                  {
                    staticClass: "icon",
                    staticStyle: {
                      "font-size": "20px",
                      "vertical-align": "middle",
                      width: "24px",
                      height: "24px",
                      padding: "0 5px 5px 5px",
                    },
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-menua-lianhe21" },
                    }),
                  ]
                ),
          ]
        ),
        _vm._m(0),
      ]),
      _c("ul", { staticClass: "nav navbar-top-links navbar-right" }, [
        _c("li", { staticStyle: { padding: "20px" } }, [
          _c("span", { staticClass: "m-r-sm text-muted welcome-message" }, [
            _vm._v(_vm._s(_vm.userInfo.companyName)),
          ]),
        ]),
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_c("i", { staticClass: "fa fa-sign-out" }), _vm._v(" 退出 ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        staticClass: "navbar-form-custom",
        attrs: { role: "search", action: "search_results.html" },
      },
      [_c("div", { staticClass: "form-group" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }